import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Monitor } from '../../../core/webapi';

@Injectable({ providedIn: 'root' })
export class MonitorProvider {
  portal!: Monitor.Po | Monitor.BookStart | Monitor.Vmbo;
  constructor(router: Router, @Inject(DOCUMENT) document: Document) {
    this.setPortal({ url: document.location.href });
    router.events.pipe(filter((x): x is NavigationStart => x instanceof NavigationStart)).subscribe((x) => this.setPortal(x));
  }

  private setPortal(x: { url: string }): void {
    const parts = x.url.split('/');
    this.portal = parts.includes('bookstart') ? Monitor.BookStart : parts.includes('vo') ? Monitor.Vmbo : Monitor.Po;
  }
}
